import ShareSheet from '~/shared/modules/share-sheet';

document.addEventListener('DOMContentLoaded', () => {
  const sharedWrappers = document.querySelectorAll('.js-share-actions');

  if (sharedWrappers) {
    sharedWrappers.forEach((el) => {
      const {
        area, selector, body, utmSource, utmCampaign, utmContent, trackCollection,
      } = el.dataset;
      const options = {
        area,
        selector,
        body,
        utm: {
          source: utmSource,
          campaign: utmCampaign,
          content: utmContent,
        },
        outlets: [
          'facebook',
          'twitter',
          'clipboard',
        ],
        attach: {
          facebook: '.js-facebook-btn',
          twitter: '.js-twitter-btn',
          clipboard: '.js-clipboard-btn',
        },
        clipboardLink: true,
      };

      if (trackCollection) {
        options.trackCollectionShare = trackCollection;
      }

      new ShareSheet(options);
    });
  }
});
